const buttons = document.querySelectorAll('.tr_features__inner__buttons__button');
const images = Array.from(document.querySelectorAll('.tr_features__inner__images__image'));
const BUTTON_CLASSNAME = 'tr_features__inner__buttons__button_active';
const IMAGE_CLASSNAME = 'tr_features__inner__images__image_active';
const datasetName = 'number';

const onButtonClick = (event) => {
  const slideNumber = event.currentTarget.dataset[datasetName];
  const imageToShow = images.filter((element) => element.dataset[datasetName] === slideNumber);

  if (slideNumber && imageToShow.length) {
    buttons.forEach((element) => element.classList.remove(BUTTON_CLASSNAME));
    images.forEach((element) => element.classList.remove(IMAGE_CLASSNAME));
    event.currentTarget.classList.add(BUTTON_CLASSNAME);
    imageToShow.forEach((element) => element.classList.add(IMAGE_CLASSNAME));
  }
};

export default () => {
  buttons.forEach((el) => {
    el.addEventListener('click', onButtonClick);
  });
};
